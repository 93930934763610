<template>
    <super-admin-auth-layout>
        <div @click="errorMessage=''" class="mt-4">
            <div class="text-center mb-5">
                <h5 class="fs-lg-6 mb-1 font-inter-semi-bold">Forgot Password</h5>
                <p class="font-inter-medium fs--1 ml-2 text-5 text-left">
                    Forgot your password? Please enter your username.
                </p>
            </div>
            <s-form>
                <validated-input label="Email ID" placeholder="Enter your email ID" type="email"
                                 v-model="model.email" class="text-secondary mb-1 c-form-input"
                                 :disabled="loading" :rules="rules.email"/>
                <span class="text-danger" v-if="errorMessage" v-html="errorMessage"></span>
<!--                <p class="mb-4 text-right font-inter-medium text-5 link fs&#45;&#45;1">-->
<!--                    Not yet received ?-->
<!--                    <span class="text-secondary ml-2"><router-link to="/">Resend</router-link></span>-->
<!--                </p>-->
                <div class="text-center mt-5">
                    <btn class="pl-5 py-2 pr-5 py-2 font-inter-medium" block text="Submit" loading-text="Validating..." size=""
                         :disabled="loading" :loading="loading"/>
                </div>
                <p class="mt-4 text-center font-inter-medium fs--1 ml-2 text-5 text-left">
                    Please check your email. You will receive a link to create a new password.
                </p>
            </s-form>
            <div class="mt-5 text-center">
                <router-link to="/login/"
                             class="text-primary fs--1 font-inter-medium text-decoration-none">
                    Back to Login
                </router-link>
            </div>

        </div>
    </super-admin-auth-layout>
</template>

<script>
import SuperAdminAuthLayout from '@/views/auth/super-admin-auth/SuperAdminAuthPageLayout';
import urls from '@/data/urls';

export default {
    name       : 'SuperAdminForgotPassword',
    components : { SuperAdminAuthLayout },
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    }
};
</script>

<style scoped>

</style>
